import React, { useContext, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import "./Contact.scss";
// import SocialMedia from "../../components/socialMedia/SocialMedia";
// import { illustration, contactInfo } from "../../portfolio";
import { Fade } from "react-reveal";
// import email from "../../assets/lottie/email";
// import DisplayLottie from "../../components/displayLottie/DisplayLottie";
import StyleContext from "../../contexts/StyleContext";
import SuccessBar from "../../components/Snackbar/SuccessSnackbar";
import ErrorBar from "../../components/Snackbar/ErrorSnackbar";

export default function Contact() {
  const { isDark } = useContext(StyleContext);

  const [successMsgs, setSuccessMsgs] = useState("");
  const [errorMsgs, setErrorMsgs] = useState("");
  const [successSnackBarOpen, setSuccessSnackBarOpen] = useState(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);

  const serviceId = process.env.REACT_APP_SERVICE_ID;
  const templateId = process.env.REACT_APP_TEMPLATE_ID;
  const publicKey = process.env.REACT_APP_PUBLIC_KEY;

  const form = useRef();

  const validateForm = () => {
    const formData = new FormData(form.current);
    for (let value of formData.values()) {
      if (value.trim() === "") {
        return false;
      }
    }
    return true;
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      setErrorMsgs("All fields are required.");
      setErrorSnackbarOpen(true);
      return;
    }

    emailjs
      .sendForm(`${serviceId}`, `${templateId}`, form.current, {
        publicKey: `${publicKey}`,
      })
      .then(
        () => {
          // console.log("SUCCESS!");
          setSuccessMsgs("Your message has been sent successfully.");
          setSuccessSnackBarOpen(true);
        },
        (error) => {
          // console.log("FAILED...", error.text);
          setErrorMsgs("Something went wrong...");
          setErrorSnackbarOpen(true);
        }
      );
  };

  return (
    <>
      <SuccessBar
        snackbarOpen={successSnackBarOpen}
        setSnackbarOpen={setSuccessSnackBarOpen}
        message={successMsgs}
      />
      <ErrorBar
        snackbarOpen={errorSnackbarOpen}
        setSnackbarOpen={setErrorSnackbarOpen}
        message={errorMsgs}
      />
      <div className="main contact-margin-top" id="contact">
        {/* <div className="contact-div-main">
          <div className="contact-header">
            <h1 className="heading contact-title">{contactInfo.title}</h1>
            <p
              className={
                isDark
                  ? "dark-mode contact-subtitle"
                  : "subTitle contact-subtitle"
              }
            >
              {contactInfo.subtitle}
            </p>
            <div
              className={
                isDark ? "dark-mode contact-text-div" : "contact-text-div"
              }
            >
              {contactInfo.number && (
                <>
                  <a
                    className="contact-detail"
                    href={"tel:" + contactInfo.number}
                  >
                    {contactInfo.number}
                  </a>
                  <br />
                  <br />
                </>
              )}
              <a
                className="contact-detail-email"
                href={"mailto:" + contactInfo.email_address}
              >
                {contactInfo.email_address}
              </a>
              <br />
              <br />
              <SocialMedia />
            </div>
          </div>
          <div className="contact-image-div">
            {illustration.animated ? (
              <DisplayLottie animationData={email} />
            ) : (
              <img
                alt="Man working"
                src={require("../../assets/images/contactMailDark.svg")}
              ></img>
            )}
          </div>
        </div> */}
        <div
          style={{
            textAlign: "center",
            padding: 8,
            fontSize: "clamp(1rem, 5vw, 3rem)",
            color: "#fff",
          }}
        >
          <p>Connect With Me</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          <Fade left duration={1000}>
            <div
              style={{
                position: "static",
                display: "grid",
                height: "auto",
                width: { sm: "100%", md: "100%", lg: "25%" },
                minHeight: "auto",
                gridTemplateRows: "min-content 1fr",
                gridTemplateColumns: "100%",
              }}
              class="form-card1"
            >
              <div class="form-card2">
                <form ref={form} onSubmit={sendEmail} class="form">
                  <p class="form-heading">Give Me a Voice</p>
                  <div class="form-field">
                    <input
                      required=""
                      name="from_name"
                      placeholder="Name"
                      class="input-field"
                      type="text"
                    />
                  </div>
                  <div class="form-field">
                    <input
                      required=""
                      name="from_email"
                      placeholder="Email"
                      class="input-field"
                      type="email"
                    />
                  </div>
                  <div class="form-field">
                    <input
                      required=""
                      name="subject"
                      placeholder="Subject"
                      class="input-field"
                      type="text"
                    />
                  </div>
                  <div class="form-field">
                    <textarea
                      required=""
                      name="message"
                      placeholder="Message"
                      cols="30"
                      rows="6"
                      class="input-field"
                    ></textarea>
                  </div>
                  {/* <button class="sendMessage-btn">Send Message</button> */}
                  {/* <button>
                  <div class="svg-wrapper-1">
                    <div class="svg-wrapper">
                      <svg
                        height="24"
                        width="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0h24v24H0z" fill="none"></path>
                        <path
                          d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                  </div>
                  <span>Send</span>
                </button> */}
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button class="button" style={{ width: "50%" }}>
                      <div class="button__int">
                        <span class="button__span">Send</span>
                      </div>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </>
  );
}
