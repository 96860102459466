import aws from "../assets/projectsImage/awsML.png";
import icpc from "../assets/projectsImage/ICPC.png";
import isb from "../assets/projectsImage/ISB.png";
import caltech from "../assets/projectsImage/Caltech-options.png";
import quantpy from "../assets/projectsImage/quantpy.png";
import upwork from "../assets/projectsImage/Upwork.png";

export const certificateData = [
    {
        "id": 1,
        "imageUrl": `${aws}`,
        "name": "Ishaan Gupta",
        "description": "AWS Machine Learning Specialist"
    },
    {
        "id": 2,
        "imageUrl": `${icpc}`,
        "name": "Ishaan Gupta",
        "description": "ACM ICPC 2019 Regionals"
    },
    {
        "id": 3,
        "imageUrl": `${isb}`,
        "name": "Ishaan Gupta",
        "description": "Indian School of Business- Advanced Trading Algorithms"
    },
    {
        "id": 4,
        "imageUrl": `${caltech}`,
        "name": "Ishaan Gupta",
        "description": "Caltech- Pricing Options with Mathematical Models"
    },
    {
        "id": 5,
        "imageUrl": `${quantpy}`,
        "name": "Ishaan Gupta",
        "description": "QuantPy- Machine Learning for Quantitative Finance"
    },
    {
        "id": 6,
        "imageUrl": `${upwork}`,
        "name": "Ishaan Gupta",
        "description": "Upwork- Top Rated Plus Freelancer"
    }
]