import ProBox from "../../components/ProjectBox/probox";
import "./globals.css";
import websiteImage from "../../assets/projectsImage/iamvector.com_svg-editor.png";
import { Fade } from "react-reveal";
import { useContext } from "react";
import StyleContext from "../../contexts/StyleContext";

import { projectData } from "../../jsonData/ProjectsData";

// const projectData = [
//   {
//     title: "Ecommerce Store",
//     img: `${websiteImage}`,
//     date: ` (March 2024)`,
//     description:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//     techno1: "React",
//     techno2: "Python",
//     code: "",
//     demo: "",
//     scrollY: "-50%",
//     icon: "🛒",
//     cName: "reversed-proj",
//   },
//   {
//     title: "Ecommerce Store",
//     img: `${websiteImage}`,
//     date: ` (March 2024)`,
//     description:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//     techno1: "React",
//     techno2: "PostgresSQL",
//     code: "",
//     demo: "",
//     scrollY: "-74%",
//     icon: "🍪",
//     cName: "",
//   },
//   {
//     title: "Ecommerce Store",
//     img: `${websiteImage}`,
//     date: ` (March 2024)`,
//     description:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//     techno1: "React",
//     techno2: "Python",
//     code: "",
//     demo: "",
//     scrollY: "-84%",
//     icon: "🏨",
//     cName: "reversed-proj",
//   },
// ];

function Projects() {
  const { isDark } = useContext(StyleContext);
  return (
    <>
      <div
        style={{
          borderRadius: "48px",
        }}
        className="mx-auto max-w-7xl"
        id="project"
      >
        <section id="projects" className="project flex justify-center">
          <div className="container">
            <div className="project-content lg:px-32">
              <div
                style={{
                  textAlign: "center",
                  padding: 4,
                  borderRadius: "24px",
                  // backgroundColor: "#55198b",
                  width: "100%",
                }}
              >
                <Fade left duration={1000}>
                  <p
                    style={{
                      padding: 2,
                      fontSize: "clamp(1rem, 5vw, 3rem)",
                      fontWeight: 900,
                      color: "#55198b",
                      paddingInline: "2.5rem",
                    }}
                  >
                    Projects
                  </p>
                </Fade>
              </div>{" "}
              <Fade Right duration={1000}>
                <h3
                  color={isDark ? "#fff" : ""}
                  style={{
                    fontSize: "clamp(14px, 3vw, 24px)",
                    textAlign: "center",
                  }}
                >
                  Each project is a unique piece of development 🧩
                </h3>
              </Fade>
              <div style={{ marginBottom: 100 }} className="projects-grid">
                {projectData.map((item, index) => (
                  <Fade
                    left={index % 2 === 0}
                    right={index % 2 !== 0}
                    duration={1000}
                  >
                    <ProBox
                      title={item.title}
                      img={item.img}
                      date={item.date}
                      description={item.description}
                      techno1={item.techno1}
                      techno2={item.techno2}
                      code={item.code}
                      demo={item.demo}
                      scrollY={item.scrollY}
                      icon={item.icon}
                      cName={index % 2 === 0 ? "reversed-proj" : ""}
                    />
                  </Fade>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Projects;
