import React from "react";
import "./SocialMedia.scss";
import { socialMediaLinks } from "../../portfolio";
import { Link } from "@mui/material";

export default function socialMedia() {
  if (!socialMediaLinks.display) {
    return null;
  }
  return (
    // <div className="social-media-div">
    //   {socialMediaLinks.github ? (
    //     <a
    //       href={socialMediaLinks.github}
    //       className="icon-button github"
    //       target="_blank" rel="noopener noreferrer"
    //       rel="noopener noreferrerreferrer"
    //     >
    //       <i className="fab fa-github"></i>
    //       <span></span>
    //     </a>
    //   ) : null}

    //   {socialMediaLinks.linkedin ? (
    //     <a
    //       href={socialMediaLinks.linkedin}
    //       className="icon-button linkedin"
    //       target="_blank" rel="noopener noreferrer"
    //       rel="noopener noreferrerreferrer"
    //     >
    //       <i className="fab fa-linkedin-in"></i>
    //       <span></span>
    //     </a>
    //   ) : null}

    //   {socialMediaLinks.gmail ? (
    //     <a
    //       href={`mailto:${socialMediaLinks.gmail}`}
    //       className="icon-button google"
    //       target="_blank" rel="noopener noreferrer"
    //       rel="noopener noreferrerreferrer"
    //     >
    //       <i className="fas fa-envelope"></i>
    //       <span></span>
    //     </a>
    //   ) : null}

    //   {socialMediaLinks.gitlab ? (
    //     <a
    //       href={socialMediaLinks.gitlab}
    //       className="icon-button gitlab"
    //       target="_blank" rel="noopener noreferrer"
    //       rel="noopener noreferrerreferrer"
    //     >
    //       <i className="fab fa-gitlab"></i>
    //       <span></span>
    //     </a>
    //   ) : null}

    //   {socialMediaLinks.facebook ? (
    //     <a
    //       href={socialMediaLinks.facebook}
    //       className="icon-button facebook"
    //       target="_blank" rel="noopener noreferrer"
    //       rel="noopener noreferrerreferrer"
    //     >
    //       <i className="fab fa-facebook-f"></i>
    //       <span></span>
    //     </a>
    //   ) : null}

    //   {socialMediaLinks.instagram ? (
    //     <a
    //       href={socialMediaLinks.instagram}
    //       className="icon-button instagram"
    //       target="_blank" rel="noopener noreferrer"
    //       rel="noopener noreferrerreferrer"
    //     >
    //       <i className="fab fa-instagram"></i>
    //       <span></span>
    //     </a>
    //   ) : null}

    //   {socialMediaLinks.twitter ? (
    //     <a
    //       href={socialMediaLinks.twitter}
    //       className="icon-button twitter"
    //       target="_blank" rel="noopener noreferrer"
    //       rel="noopener noreferrerreferrer"
    //     >
    //       <i className="fab fa-twitter"></i>
    //       <span></span>
    //     </a>
    //   ) : null}

    //   {socialMediaLinks.medium ? (
    //     <a
    //       href={socialMediaLinks.medium}
    //       className="icon-button medium"
    //       target="_blank" rel="noopener noreferrer"
    //       rel="noopener noreferrerreferrer"
    //     >
    //       <i className="fab fa-medium"></i>
    //       <span></span>
    //     </a>
    //   ) : null}

    //   {socialMediaLinks.stackoverflow ? (
    //     <a
    //       href={socialMediaLinks.stackoverflow}
    //       className="icon-button stack-overflow"
    //       target="_blank" rel="noopener noreferrer"
    //       rel="noopener noreferrerreferrer"
    //     >
    //       <i className="fab fa-stack-overflow"></i>
    //       <span></span>
    //     </a>
    //   ) : null}

    //   {socialMediaLinks.kaggle ? (
    //     <a
    //       href={socialMediaLinks.kaggle}
    //       className="icon-button kaggle"
    //       target="_blank" rel="noopener noreferrer"
    //       rel="noopener noreferrerreferrer"
    //     >
    //       <i className="fab fa-kaggle"></i>
    //       <span></span>
    //     </a>
    //   ) : null}

    //   {socialMediaLinks.outlook ? (
    //     <a
    //       href={socialMediaLinks.outlook}
    //       className="icon-button outlook"
    //       target="_blank" rel="noopener noreferrer"
    //       rel="noopener noreferrerreferrer"
    //     >
    //       <i className="fab fa-microsoft"></i>
    //       <span></span>
    //     </a>
    //   ) : null}
    // </div>

    <div class="mainsocial-container">
      <div class="quant-card">
        <a href="www.instagram.com" target="_blank" rel="noopener noreferrer">
          {/* <svg
            fill-rule="nonzero"
            height="30px"
            width="30px"
            viewBox="0,0,256,256"
            xlink="http://www.w3.org/1999/xlink"
            xmlns="http://www.w3.org/2000/svg"
            class="instagram"
          >
            <title>Instagram</title> */}

            <g
              style={{ mixBlendMode: "normal" }}
              text-anchor="none"
              font-size="none"
              font-weight="none"
              font-family="none"
              stroke-dashoffset="0"
              stroke-dasharray=""
              stroke-miterlimit="10"
              stroke-linejoin="miter"
              stroke-linecap="butt"
              stroke-width="1"
              stroke="none"
              fill-rule="nonzero"
            >
              <g transform="scale(8,8)">
                <path d="M11.46875,5c-3.55078,0 -6.46875,2.91406 -6.46875,6.46875v9.0625c0,3.55078 2.91406,6.46875 6.46875,6.46875h9.0625c3.55078,0 6.46875,-2.91406 6.46875,-6.46875v-9.0625c0,-3.55078 -2.91406,-6.46875 -6.46875,-6.46875zM11.46875,7h9.0625c2.47266,0 4.46875,1.99609 4.46875,4.46875v9.0625c0,2.47266 -1.99609,4.46875 -4.46875,4.46875h-9.0625c-2.47266,0 -4.46875,-1.99609 -4.46875,-4.46875v-9.0625c0,-2.47266 1.99609,-4.46875 4.46875,-4.46875zM21.90625,9.1875c-0.50391,0 -0.90625,0.40234 -0.90625,0.90625c0,0.50391 0.40234,0.90625 0.90625,0.90625c0.50391,0 0.90625,-0.40234 0.90625,-0.90625c0,-0.50391 -0.40234,-0.90625 -0.90625,-0.90625zM16,10c-3.30078,0 -6,2.69922 -6,6c0,3.30078 2.69922,6 6,6c3.30078,0 6,-2.69922 6,-6c0,-3.30078 -2.69922,-6 -6,-6zM16,12c2.22266,0 4,1.77734 4,4c0,2.22266 -1.77734,4 -4,4c-2.22266,0 -4,-1.77734 -4,-4c0,-2.22266 1.77734,-4 4,-4z"></path>
              </g>
            </g>
          {/* </svg> */}
        </a>
      </div>
      <div class="quant-card">
        {/* <a href="www.instagram.com" target="_blank" rel="noopener noreferrer">
          <svg
            height="30px"
            width="30px"
            viewBox="0 0 48 48"
            xmlns="http://www.w3.org/2000/svg"
            class="twitter"
          >
            <title>Twitter</title>

            <path d="M42,12.429c-1.323,0.586-2.746,0.977-4.247,1.162c1.526-0.906,2.7-2.351,3.251-4.058c-1.428,0.837-3.01,1.452-4.693,1.776C34.967,9.884,33.05,9,30.926,9c-4.08,0-7.387,3.278-7.387,7.32c0,0.572,0.067,1.129,0.193,1.67c-6.138-0.308-11.582-3.226-15.224-7.654c-0.64,1.082-1,2.349-1,3.686c0,2.541,1.301,4.778,3.285,6.096c-1.211-0.037-2.351-0.374-3.349-0.914c0,0.022,0,0.055,0,0.086c0,3.551,2.547,6.508,5.923,7.181c-0.617,0.169-1.269,0.263-1.941,0.263c-0.477,0-0.942-0.054-1.392-0.135c0.94,2.902,3.667,5.023,6.898,5.086c-2.528,1.96-5.712,3.134-9.174,3.134c-0.598,0-1.183-0.034-1.761-0.104C9.268,36.786,13.152,38,17.321,38c13.585,0,21.017-11.156,21.017-20.834c0-0.317-0.01-0.633-0.025-0.945C39.763,15.197,41.013,13.905,42,12.429"></path>
          </svg>
        </a> */}
      </div>
      <div class="quant-card">
        <a href="https://medium.com/@ishaan.gupta0401" target="_blank" rel="noopener noreferrer">
          <svg
            width="30px"
            height="30px"
            viewBox="0 -55 256 256"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
            preserveAspectRatio="xMidYMid"
            className="medium"
          >
            <title>Medium</title>
            <g>
              <path d="M72.2009141,1.42108547e-14 C112.076502,1.42108547e-14 144.399375,32.5485469 144.399375,72.6964154 C144.399375,112.844284 112.074049,145.390378 72.2009141,145.390378 C32.327779,145.390378 0,112.844284 0,72.6964154 C0,32.5485469 32.325326,1.42108547e-14 72.2009141,1.42108547e-14 Z M187.500628,4.25836743 C207.438422,4.25836743 223.601085,34.8960455 223.601085,72.6964154 L223.603538,72.6964154 C223.603538,110.486973 207.440875,141.134463 187.503081,141.134463 C167.565287,141.134463 151.402624,110.486973 151.402624,72.6964154 C151.402624,34.9058574 167.562834,4.25836743 187.500628,4.25836743 Z M243.303393,11.3867175 C250.314,11.3867175 256,38.835526 256,72.6964154 C256,106.547493 250.316453,134.006113 243.303393,134.006113 C236.290333,134.006113 230.609239,106.554852 230.609239,72.6964154 C230.609239,38.837979 236.292786,11.3867175 243.303393,11.3867175 Z"></path>
            </g>
          </svg>
        </a>
      </div>
      <div class="quant-card">
        <a href="www.instagram.com" target="_blank" rel="noopener noreferrer">
          {/* <svg
            width="30px"
            height="30px"
            viewBox="0 -10 256 256"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
            preserveAspectRatio="xMidYMid"
            class="gitlab"
          > */}
            {/* <title>Gitlab</title> */}

            <g>
              <path
                d="M128.07485,236.074667 L128.07485,236.074667 L175.17885,91.1043048 L80.9708495,91.1043048 L128.07485,236.074667 L128.07485,236.074667 Z"
                fill="#E24329"
              ></path>
              <path
                d="M128.07485,236.074423 L80.9708495,91.104061 L14.9557638,91.104061 L128.07485,236.074423 L128.07485,236.074423 Z"
                fill="#FC6D26"
              ></path>
              <path
                d="M14.9558857,91.1044267 L14.9558857,91.1044267 L0.641828571,135.159589 C-0.663771429,139.17757 0.766171429,143.57955 4.18438095,146.06275 L128.074971,236.074789 L14.9558857,91.1044267 L14.9558857,91.1044267 Z"
                fill="#FCA326"
              ></path>
              <path
                d="M14.9558857,91.1045486 L80.9709714,91.1045486 L52.6000762,3.79026286 C51.1408762,-0.703146667 44.7847619,-0.701927619 43.3255619,3.79026286 L14.9558857,91.1045486 L14.9558857,91.1045486 Z"
                fill="#E24329"
              ></path>
              <path
                d="M128.07485,236.074423 L175.17885,91.104061 L241.193935,91.104061 L128.07485,236.074423 L128.07485,236.074423 Z"
                fill="#FC6D26"
              ></path>
              <path
                d="M241.193935,91.1044267 L241.193935,91.1044267 L255.507992,135.159589 C256.813592,139.17757 255.38365,143.57955 251.96544,146.06275 L128.07485,236.074789 L241.193935,91.1044267 L241.193935,91.1044267 Z"
                fill="#FCA326"
              ></path>
              <path
                d="M241.193935,91.1045486 L175.17885,91.1045486 L203.549745,3.79026286 C205.008945,-0.703146667 211.365059,-0.701927619 212.824259,3.79026286 L241.193935,91.1045486 L241.193935,91.1045486 Z"
                fill="#E24329"
              ></path>
            </g>
          {/* </svg> */}
        </a>
      </div>
      <div class="quant-card">
        <a href="https://www.linkedin.com/in/ishaan-gupta04/" target="_blank" rel="noopener noreferrer">
          {/* <svg
            width="30px"
            height="30px"
            viewBox="0 0 0.6 0.6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            class="linkedin"
          >
            <title>LinkedIn</title>

            <path
              fill="#0A66C2"
              d="M0.458 0.458h-0.067V0.354c0 -0.025 0 -0.057 -0.035 -0.057 -0.035 0 -0.04 0.027 -0.04 0.055v0.106H0.25V0.244h0.064v0.029h0.001c0.013 -0.022 0.037 -0.036 0.063 -0.035 0.068 0 0.08 0.044 0.08 0.102zM0.175 0.214a0.039 0.039 0 0 1 -0.039 -0.039c0 -0.021 0.017 -0.039 0.039 -0.039s0.039 0.017 0.039 0.039c0 0.021 -0.017 0.039 -0.039 0.039zm0.033 0.244h-0.067V0.244h0.067zM0.492 0.075H0.108A0.033 0.033 0 0 0 0.075 0.107v0.385a0.033 0.033 0 0 0 0.033 0.032h0.383a0.033 0.033 0 0 0 0.033 -0.032V0.107a0.033 0.033 0 0 0 -0.033 -0.032z"
            />
          </svg> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="30"
            height="30"
            viewBox="0 0 48 48"
            className="linkedin"
          >
            <title>LinkedIn</title>
            <path
              fill="#0288D1"
              d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"
            ></path>
            <path
              fill="#FFF"
              d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"
            ></path>
          </svg>
        </a>
      </div>
      <div class="quant-card">
        <a href="www.instagram.com" target="_blank" rel="noopener noreferrer">
          {/* <svg
            width="30px"
            height="30px"
            viewBox="0 0 32 32"
            data-name="Layer 1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            class="gmail"
          >
            <path
              d="M16.58,19.1068l-12.69-8.0757A3,3,0,0,1,7.1109,5.97l9.31,5.9243L24.78,6.0428A3,3,0,0,1,28.22,10.9579Z"
              fill="#ea4435"
            ></path>
            <path
              d="M25.5,5.5h4a0,0,0,0,1,0,0v18a3,3,0,0,1-3,3h0a3,3,0,0,1-3-3V7.5a2,2,0,0,1,2-2Z"
              fill="#00ac47"
              transform="translate(53.0001 32.0007) rotate(180)"
            ></path>
            <path
              d="M29.4562,8.0656c-.0088-.06-.0081-.1213-.0206-.1812-.0192-.0918-.0549-.1766-.0823-.2652a2.9312,2.9312,0,0,0-.0958-.2993c-.02-.0475-.0508-.0892-.0735-.1354A2.9838,2.9838,0,0,0,28.9686,6.8c-.04-.0581-.09-.1076-.1342-.1626a3.0282,3.0282,0,0,0-.2455-.2849c-.0665-.0647-.1423-.1188-.2146-.1771a3.02,3.02,0,0,0-.24-.1857c-.0793-.0518-.1661-.0917-.25-.1359-.0884-.0461-.175-.0963-.267-.1331-.0889-.0358-.1837-.0586-.2766-.0859s-.1853-.06-.2807-.0777a3.0543,3.0543,0,0,0-.357-.036c-.0759-.0053-.1511-.0186-.2273-.018a2.9778,2.9778,0,0,0-.4219.0425c-.0563.0084-.113.0077-.1689.0193a33.211,33.211,0,0,0-.5645.178c-.0515.022-.0966.0547-.1465.0795A2.901,2.901,0,0,0,23.5,8.5v5.762l4.72-3.3043a2.8878,2.8878,0,0,0,1.2359-2.8923Z"
              fill="#ffba00"
            ></path>
            <path
              d="M5.5,5.5h0a3,3,0,0,1,3,3v18a0,0,0,0,1,0,0h-4a2,2,0,0,1-2-2V8.5a3,3,0,0,1,3-3Z"
              fill="#4285f4"
            ></path>
            <path
              d="M2.5439,8.0656c.0088-.06.0081-.1213.0206-.1812.0192-.0918.0549-.1766.0823-.2652A2.9312,2.9312,0,0,1,2.7426,7.32c.02-.0475.0508-.0892.0736-.1354A2.9719,2.9719,0,0,1,3.0316,6.8c.04-.0581.09-.1076.1342-.1626a3.0272,3.0272,0,0,1,.2454-.2849c.0665-.0647.1423-.1188.2147-.1771a3.0005,3.0005,0,0,1,.24-.1857c.0793-.0518.1661-.0917.25-.1359A2.9747,2.9747,0,0,1,4.3829,5.72c.089-.0358.1838-.0586.2766-.0859s.1853-.06.2807-.0777a3.0565,3.0565,0,0,1,.357-.036c.076-.0053.1511-.0186.2273-.018a2.9763,2.9763,0,0,1,.4219.0425c.0563.0084.113.0077.169.0193a2.9056,2.9056,0,0,1,.286.0888,2.9157,2.9157,0,0,1,.2785.0892c.0514.022.0965.0547.1465.0795a2.9745,2.9745,0,0,1,.3742.21A2.9943,2.9943,0,0,1,8.5,8.5v5.762L3.78,10.9579A2.8891,2.8891,0,0,1,2.5439,8.0656Z"
              fill="#c52528"
            ></path>
          </svg> */}
        </a>
      </div>
      <div class="quant-card">
        <a href="https://github.com/Ish2K" target="_blank" rel="noopener noreferrer">
          <svg
            height="30px"
            width="30px"
            viewBox="0 0 30 30"
            xmlns="http://www.w3.org/2000/svg"
            class="github"
          >
            <title>GitHub</title>

            <path d="M15,3C8.373,3,3,8.373,3,15c0,5.623,3.872,10.328,9.092,11.63C12.036,26.468,12,26.28,12,26.047v-2.051 c-0.487,0-1.303,0-1.508,0c-0.821,0-1.551-0.353-1.905-1.009c-0.393-0.729-0.461-1.844-1.435-2.526 c-0.289-0.227-0.069-0.486,0.264-0.451c0.615,0.174,1.125,0.596,1.605,1.222c0.478,0.627,0.703,0.769,1.596,0.769 c0.433,0,1.081-0.025,1.691-0.121c0.328-0.833,0.895-1.6,1.588-1.962c-3.996-0.411-5.903-2.399-5.903-5.098 c0-1.162,0.495-2.286,1.336-3.233C9.053,10.647,8.706,8.73,9.435,8c1.798,0,2.885,1.166,3.146,1.481C13.477,9.174,14.461,9,15.495,9 c1.036,0,2.024,0.174,2.922,0.483C18.675,9.17,19.763,8,21.565,8c0.732,0.731,0.381,2.656,0.102,3.594 c0.836,0.945,1.328,2.066,1.328,3.226c0,2.697-1.904,4.684-5.894,5.097C18.199,20.49,19,22.1,19,23.313v2.734 c0,0.104-0.023,0.179-0.035,0.268C23.641,24.676,27,20.236,27,15C27,8.373,21.627,3,15,3z"></path>
          </svg>
        </a>
      </div>
      <div class="quant-card">
        <a href="www.quanttech.info" target="_blank" rel="noopener noreferrer">
          {/* <svg
            width="30px"
            height="30px"
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
            class="outlook"
          >
            <title>Outlook</title>
            <path
              d="M19.484,7.937v5.477L21.4,14.619a.489.489,0,0,0,.21,0l8.238-5.554a1.174,1.174,0,0,0-.959-1.128Z"
              style={{ fill: "#0072c6" }}
            />
            <path
              d="M19.484,15.457l1.747,1.2a.522.522,0,0,0,.543,0c-.3.181,8.073-5.378,8.073-5.378V21.345a1.408,1.408,0,0,1-1.49,1.555H19.483V15.457Z"
              style={{ fill: "#0072c6" }}
            />
            <path
              d="M10.44,12.932a1.609,1.609,0,0,0-1.42.838,4.131,4.131,0,0,0-.526,2.218A4.05,4.05,0,0,0,9.02,18.2a1.6,1.6,0,0,0,2.771.022,4.014,4.014,0,0,0,.515-2.2,4.369,4.369,0,0,0-.5-2.281A1.536,1.536,0,0,0,10.44,12.932Z"
              style={{ fill: "#0072c6" }}
            />
            <path
              d="M2.153,5.155V26.582L18.453,30V2ZM13.061,19.491a3.231,3.231,0,0,1-2.7,1.361,3.19,3.19,0,0,1-2.64-1.318A5.459,5.459,0,0,1,6.706,16.1a5.868,5.868,0,0,1,1.036-3.616A3.267,3.267,0,0,1,10.486,11.1a3.116,3.116,0,0,1,2.61,1.321,5.639,5.639,0,0,1,1,3.484A5.763,5.763,0,0,1,13.061,19.491Z"
              style={{ fill: "#0072c6" }}
            />
          </svg> */}
        </a>
      </div>
      <div class="quant-card">
        <a href="www.instagram.com" target="_blank" rel="noopener noreferrer">
          {/* <svg
            space="preserve"
            viewBox="0 0 256 256"
            height="30"
            width="30"
            version="1.1"
            xlink="http://www.w3.org/1999/xlink"
            xmlns="http://www.w3.org/2000/svg"
            class="reddit"
          >
            <title>Reddit</title>

            <defs></defs>
            <g
              transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
              // style="stroke: none; border-radius:50%; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;"
              style={{
                stroke: "none",
                borderRadius: "50%",
                strokeWidth: 0,
                strokeDasharray: "none",
                strokeLinecap: "butt",
                strokeLinejoin: "miter",
                strokeMiterlimit: 10,
                fill: "none",
                fillRule: "nonzero",
                opacity: 1,
              }}
            >
              <circle
                transform="matrix(1 0 0 1 0 0)"
                // style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill-rule: nonzero; opacity: 1;"
                style={{
                  stroke: "none",
                  strokeWidth: 0,
                  strokeDasharray: "none",
                  strokeLinecap: "butt",
                  strokeLinejoin: "miter",
                  strokeMiterlimit: 10,
                  fillRule: "nonzero",
                  opacity: 1,
                }}
                r="45"
                cy="45"
                cx="45"
              ></circle>
              <path
                stroke-linecap="round"
                transform="matrix(1 0 0 1 0 0)"
                style={{
                  stroke: "none",
                  strokeWidth: 1,
                  strokeDasharray: "none",
                  strokeLinecap: "butt",
                  strokeLinejoin: "miter",
                  strokeMiterlimit: 10,
                  fillRule: "nonzero",
                  opacity: 1,
                }}
                d="M 75.011 45 c -0.134 -3.624 -3.177 -6.454 -6.812 -6.331 c -1.611 0.056 -3.143 0.716 -4.306 1.823 c -5.123 -3.49 -11.141 -5.403 -17.327 -5.537 l 2.919 -14.038 l 9.631 2.025 c 0.268 2.472 2.483 4.262 4.955 3.993 c 2.472 -0.268 4.262 -2.483 3.993 -4.955 s -2.483 -4.262 -4.955 -3.993 c -1.421 0.145 -2.696 0.973 -3.4 2.204 L 48.68 17.987 c -0.749 -0.168 -1.499 0.302 -1.667 1.063 c 0 0.011 0 0.011 0 0.022 l -3.322 15.615 c -6.264 0.101 -12.36 2.025 -17.55 5.537 c -2.64 -2.483 -6.801 -2.36 -9.284 0.291 c -2.483 2.64 -2.36 6.801 0.291 9.284 c 0.515 0.481 1.107 0.895 1.767 1.186 c -0.045 0.66 -0.045 1.32 0 1.98 c 0 10.078 11.745 18.277 26.23 18.277 c 14.485 0 26.23 -8.188 26.23 -18.277 c 0.045 -0.66 0.045 -1.32 0 -1.98 C 73.635 49.855 75.056 47.528 75.011 45 z M 30.011 49.508 c 0 -2.483 2.025 -4.508 4.508 -4.508 c 2.483 0 4.508 2.025 4.508 4.508 s -2.025 4.508 -4.508 4.508 C 32.025 53.993 30.011 51.991 30.011 49.508 z M 56.152 62.058 v -0.179 c -3.199 2.405 -7.114 3.635 -11.119 3.468 c -4.005 0.168 -7.919 -1.063 -11.119 -3.468 c -0.425 -0.515 -0.347 -1.286 0.168 -1.711 c 0.447 -0.369 1.085 -0.369 1.544 0 c 2.707 1.98 6.007 2.987 9.362 2.83 c 3.356 0.179 6.667 -0.783 9.407 -2.74 c 0.492 -0.481 1.297 -0.47 1.779 0.022 C 56.655 60.772 56.644 61.577 56.152 62.058 z M 55.537 54.34 c -0.078 0 -0.145 0 -0.224 0 l 0.034 -0.168 c -2.483 0 -4.508 -2.025 -4.508 -4.508 s 2.025 -4.508 4.508 -4.508 s 4.508 2.025 4.508 4.508 C 59.955 52.148 58.02 54.239 55.537 54.34 z"
              ></path>
            </g>
          </svg> */}
        </a>
      </div>
      <p class="text-social">
        HOVER
        <br />
        <br />
        FOR
        <br />
        <br />
        SOCIAL
      </p>
      <div class="main_back"></div>
    </div>
  );
}
