import React, { useContext } from "react";
import { Fade } from "react-reveal";
import emoji from "react-easy-emoji";
import "./Greeting.scss";
import landingPerson from "../../assets/lottie/landingPerson";
import failure_animation from "../../assets/lottie/failure_animation";
import landingPerson2 from "../../assets/lottie/landingPerson2";
import business from "../../assets/lottie/business-analysis";
import DisplayLottie from "../../components/displayLottie/DisplayLottie";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";

import { illustration, greeting } from "../../portfolio";
import StyleContext from "../../contexts/StyleContext";

export default function Greeting() {
  const { isDark } = useContext(StyleContext);
  if (!greeting.displayGreeting) {
    return null;
  }
  return (
    <Fade bottom duration={1000} distance="40px">
      <div className="greet-main" id="greeting">
        <div>
          <h1
            style={{
              display: "flex",
              alignItems: "center",
            }}
            className={isDark ? "dark-mode greeting-text" : "greeting-text"}
          >
            {" "}
            <span className="">Welcome to</span>{" "}
            <p
              style={{
                color: "#55198b",
                fontWeight: 700,
              }}
              className="px-2 py-2"
            >
              {greeting.title}{" "}
            </p>
            <span className="wave-emoji">{emoji("👋")}</span>
          </h1>
        </div>
        <div className="greeting-main">
          <div className="greeting-text-div">
            <div>
              <p
                className={
                  isDark
                    ? "dark-mode greeting-text-p"
                    : "greeting-text-p subTitle"
                }
              >
                {greeting.subTitle}
              </p>

              <SocialMedia />
              <div className="button-greeting-div">
                <Button text="Contact Me" href="#contact" />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            className="greeting-image-div"
          >
            {illustration.animated && (
              <div class="card">
                <img
                  src="https://uiverse.io/build/_assets/astronaut-WTFWARES.png"
                  alt=""
                  class="image"
                />
                <div class="heading"></div>
                <div class="icons">
                  <a href="/" class="instagram"></a>
                  <a href="/" class="x"></a>
                  <a href="/" class="discord"></a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fade>
  );
}
