import React, { useState, useEffect, useContext } from "react";
import { Box } from "@mui/material";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';

import StyleContext from "../../contexts/StyleContext";
import { TestimonialCard } from "../../components/TestimonialCard/TestimonialCard";
import { Fade } from "react-reveal";
import { clientTesttimonialData } from "../../jsonData/ClientsTestimonialData";

export const ClientsTestimonials = () => {
    const { isDark } = useContext(StyleContext);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
        };

        handleResize(); // Check initial width
        window.addEventListener("resize", handleResize); // Listen for window resize

        return () => {
            window.removeEventListener("resize", handleResize); // Clean up
        };
    }, []);

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    infinite: true,
                    speed: 500,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    autoplay: true,
                    autoplaySpeed: 2000,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    infinite: true,
                    speed: 500,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    autoplay: true,
                    autoplaySpeed: 2000,
                },
            },
            {
                breakpoint: 748,
                settings: {
                    infinite: true,
                    speed: 500,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true,
                    autoplaySpeed: 2000,
                },
            },
        ],
    };
    return (
        <div
            id="testimonial"
            className="flex flex-col items-center justify-center w-full h-auto  px-14 py-8"
            style={{
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }}
        >
            <div style={{ display: "flex", flexDirection: "column", gap: 4, alignItems: "center", padding: 4 }}>
                <div
                    style={{
                        marginTop: 16,
                        padding: 4,
                        borderRadius: "24px",
                        // backgroundColor: "#55198b",
                        width: "fit-content",
                    }}
                >
                    <Fade left duration={1000}>
                        <p
                            style={{
                                padding: 2,
                                fontSize: "clamp(1rem, 5vw, 3rem)",
                                fontWeight: 900,
                                color: "#55198b",
                                paddingInline: "2.5rem"

                            }}
                        >
                            Client&apos;s Testimonials
                        </p>
                    </Fade>

                </div>
                <div style={{ padding: 2 }}>
                    <Fade Right duration={1000}>
                        <p style={{ fontSize: "clamp(14px, 2vw, 24px)", fontWeight: 600, color: isDark ? "#fff" : "black", padding: "1rem", marginLeft: { sm: "16px" } }}>
                            Let&apos;s see out what our Clients have to say about us.
                        </p>
                    </Fade>

                </div>
            </div>
            <Box
                // className="slider-container"
                sx={{ maxWidth: "75rem", minWidth: "8rem", width: "100%" }}
            >
                <Slider {...settings}>
                    {clientTesttimonialData.map((item, index) => (
                        <div key={index}>
                            <TestimonialCard
                                name={item.name}
                                review={item.review}
                                image={item.image}
                                description={item.description}
                            />
                        </div>
                    ))}
                </Slider>

            </Box>

        </div>
    );
};






