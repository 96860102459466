import tws from "../assets/images/tws.jpg";
import upwork from "../assets/images/upwork.png";

export const clientTesttimonialData = [
    {
        "description": "Great developer and excellent at communication and setting proper expectations. Would hire again for another python project.",
        "review": 5,
        "name": "IBKR Trading System",
        "image": `${tws}`,
    },
    {
        "description": "I had the pleasure of working with Ishaan, and I must say, I'm thoroughly impressed with the results! Ishaan delivered an exceptional code that accurately forecasts our sales.",
        "review": 5,
        "name": "Sales Forecasting for Fashion company",
        "image": `${upwork}`,
    },
    {
        "description": "Ishaan is very professional, has the ability to learn complex subjects, and is eager to solve complex problems I was very impressed by the high availability and desire to help in every possible way.",
        "review": 5,
        "name": "AI machine learning quantitative trading System",
        "image": `${tws}`,
    },
    {
        "description": "I spent 3 months working with Ishaan on a pretty complex project, developing a commodity futures trading order management system connecting two different third-party trading platforms. I found Ishaan to be an excellent partner to work with.",
        "review": 5,
        "name": "Commodities Trade Execution System",
        "image": `${tws}`,
    },
    {
        "description": "Ishaan is an exceptional software engineer. He has a rare combination of technical excellence and pragmatic problem-solving skills. He consistently delivers high-quality work and is always willing to go the extra mile to ensure the success of the project.",
        "review": 5,
        "name": "Algorithmic Trading Expert",
        "image": `${tws}`,
    },

]