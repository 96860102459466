import React, { useState } from 'react';
import './cert.scss';

import { Fade } from 'react-reveal';
import { certificateData } from '../../jsonData/CertificateData';


const Certificate = () => {
    const [modalImage, setModalImage] = useState(null);

    const openModal = (imageUrl) => {
        setModalImage(imageUrl);
    };

    const closeModal = () => {
        setModalImage(null);
    };

    return (
        <div>
            <div className="container-cert" id="certificate">
                <div
                    style={{
                        marginTop: 16,
                        padding: 4,
                        borderRadius: "24px",
                        // backgroundColor: "#55198b",
                        width: "fit-content",
                    }}
                >
                    <Fade left duration={1000}>
                        <p
                            style={{
                                padding: 2,
                                fontSize: "clamp(1rem, 5vw, 3rem)",
                                fontWeight: 900,
                                color: "#55198b",
                                paddingInline: "2.5rem"

                            }}
                        >
                            Certifications and Achievements
                        </p>
                    </Fade>

                </div>
                <div className="grid-container-cert">
                    {certificateData.map((image, index) => (
                        <Fade duration={1000} left={index % 2 === 0} right={index % 2 !== 0}>
                            <div key={image.id} className="card-cert">
                                <div className="card-content-cert">
                                    <div className="card-image-container-cert" onClick={() => openModal(image.imageUrl)}>
                                        <img src={image.imageUrl} alt="certificate" className="card-image-cert" />
                                    </div>
                                    <div className="card-text-cert">
                                        <span className="card-title-cert">{image.name}</span>
                                        <span className="card-description-cert">{image.description}</span>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                    ))}
                </div>

                {modalImage && (
                    <div className="modal-cert">
                        <div className="modal-content-cert">
                            <button className="modal-close-cert" onClick={closeModal}>
                                &times;
                            </button>
                            <img src={modalImage} alt="Full-sizeimage" className="modal-image-cert" />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Certificate;
