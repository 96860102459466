import { useContext } from "react";
import { Box, Typography, Avatar } from "@mui/material";
import Rating from "@mui/material/Rating";
import StyleContext from "../../contexts/StyleContext";
import "./testimonialCard.scss"

export const TestimonialCard = ({ name, description, review, image }) => {
    const { isDark } = useContext(StyleContext);

    return (
        <div class="e-card playing">
            <div class="image"></div>

            <div class="wave"></div>
            <div class="wave"></div>
            <div class="wave"></div>


            <div class="infotop">
                {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <svg width="70px" height="70px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="512" cy="512" r="512" style={{ fill: "#14a800" }}></circle>
                        <path d="M362.26 544.5c-18.49 0-30.73-14-34.15-19.43 4.38-34.33 17.2-45.18 34.15-45.18C379 479.88 392 493 392 512.19s-13 32.31-29.74 32.31zm0-85.69c-30.15 0-47 19.25-51.87 39.06-5.48-10.1-9.54-23.6-12.69-36.16H256v50.79c0 18.42-8.57 32.05-25.32 32.05s-26.35-13.63-26.35-32.05l.19-50.79h-24v50.79c0 14.83 4.9 28.27 13.85 37.86 9.21 9.91 21.78 15.08 36.34 15.08 29 0 49.23-21.77 49.23-52.94v-34.13c3 11.23 10.24 32.81 24 51.74L291.06 602h24.42l8.5-50.92a66.79 66.79 0 0 0 8.89 6.12 55.15 55.15 0 0 0 27.19 8.2s1.48.06 2.26.06c29.9 0 53.67-22.65 53.67-53.26s-23.83-53.39-53.73-53.39zm345.29 13.75v-10.85H684v100.52h23.9v-39.38c0-24.23 2.26-41.64 35.44-39.37v-23c-15.49-1.1-27.54 2.05-35.79 12.08zm-166-10.85L524.26 532l-18.49-70.29h-30.42L456.8 532l-17.27-70.29H415.3l26.55 100.58h28.74l20-71.43 19.78 71.43h28.74l28.09-100.58zm78.22 82.85c-17 0-29.77-13.12-29.77-32.37s12.82-32.31 29.77-32.31c16.75 0 29.77 13.12 29.77 32.31s-12.98 32.37-29.73 32.37zm0-85.75c-30.09 0-53.67 22.84-53.67 53.38s23.58 53.19 53.67 53.19c29.9 0 53.67-22.65 53.67-53.19s-23.67-53.38-53.63-53.38zm157.78 54h5.54l33.38 49.47h27l-38.4-54.83c18.62-7.13 31.12-25.74 31.12-45.75h-23.81c0 21.71-17.46 29.91-34.79 29.91V422h-24v140.29h23.9z" style={{ fill: "#fff" }}></path>
                    </svg>
                </div> */}

                <Box className='name' sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: "center", gap: 1 }}>
                    <Avatar
                        src={image}
                        alt="Testimonial"
                        sx={{ width: 50, height: 'auto', borderRadius: '50%' }}
                    />
                    <Typography
                        // variant="subtitle1"
                        fontWeight="bold"
                        sx={{ color: isDark ? 'white' : '#1c3557' }}
                    >
                        {name}
                    </Typography>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', py: 1 }}>
                    <Rating
                        value={review}
                        precision={0.5}
                        max={5}
                        sx={{ color: 'yellow', fontSize: '2rem' }}
                        readOnly
                    />
                </Box>

                <Box sx={{ color: 'white', flexGrow: 1, padding: 2 }}>
                    <Typography variant="body1" align="start">
                        {description}
                    </Typography>
                </Box>

            </div>
        </div>
    );
};
