import image from "../assets/projectsImage/darktech.jpg";
import volvo from "../assets/images/volvo.jpg";
import upwork from "../assets/images/upwork.png";

export const workHistoryData = [
    {
        "id": 1,
        "imageUrl": `${volvo}`,
        "title": "Ishaan Gupta",
        "position": "Data Scientist VECV, Indore, India",
        "timePeriod": "Oct 2021 - Apr 2022",
        "description": "Developed an automated system to monitor engine temperature and generate vehicle health alerts. Set up cron jobs for Python scripts and used data analysis libraries (NumPy, Pandas, Scikit-learn, TensorFlow, PyTorch, PySpark) and machine learning algorithms (DBSCAN, ensemble methods, linear regression). Improved data merging efficiency, reducing time from 24 to 2 hours, and ensured secure operations with binary semaphore. Designed the system using LucidCharts."
    },
    {
        "id": 2,
        "imageUrl": `${upwork}`,
        "title": "Ishaan Gupta",
        "position": "Top Rated Freelancer Upwork",
        "timePeriod": "Apr 2022 - present",
        "description": "As a Backend Software Engineer, I provided services in requirement gathering, architectural design, clean implementation, testing, documentation, bug fixing, and feature addition. I specialized in developing backend components using Python frameworks like FAST API, Django, and Flask, working across domains such as Quantitative Finance, Healthcare, and Education. As part of the Upwork Community, I completed 40+ projects with a 100% success rate, generated over $100K in revenue, and received the Top Rated Plus badge."
    }
]