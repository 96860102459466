import React, { useContext } from "react";
import Headroom from "react-headroom";
import "./Header.scss";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import StyleContext from "../../contexts/StyleContext";
import {
  greeting,
  workExperiences,
  skillsSection,
  openSource,
  blogSection,
  talkSection,
  achievementSection,
  projectsSection,
  testimonialSection,
  workHistory,
  certificateSection,
} from "../../portfolio";

function Header() {
  const { isDark } = useContext(StyleContext);
  const viewExperience = workExperiences.display;
  const viewOpenSource = openSource.display;
  const viewSkills = skillsSection.display;
  const viewAchievement = achievementSection.display;
  const viewBlog = blogSection.display;
  const viewTalks = talkSection.display;
  const projectView = projectsSection.display;
  const testimonialView = testimonialSection.display;
  const workHistoryView = workHistory.display;
  const certificateView = certificateSection.display;

  return (
    <Headroom>
      <header className={isDark ? "dark-menu header" : "header"}>
        <a href="/" className="logo">
          <span className="grey-color"> &lt;</span>
          <span className="logo-name">{greeting.username}</span>
          <span className="grey-color">/&gt;</span>
        </a>
        <input className="menu-btn" type="checkbox" id="menu-btn" />
        <label
          className="menu-icon"
          htmlFor="menu-btn"
          style={{ color: "white" }}
        >
          <span className={isDark ? "navicon navicon-dark" : "navicon"}></span>
        </label>
        <ul
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 2,
          }}
          className={isDark ? "dark-menu menu" : "menu"}
        >
          {workHistoryView && (
            <li>
              <a href="#work-history">Work History</a>
            </li>
          )}
          {testimonialView && (
            <li>
              <a href="#testimonial">Testimonial</a>
            </li>
          )}

          {/* {viewSkills && (
            <li>
              <a href="#skills">Skills</a>
            </li>
          )} */}
          {certificateView && (
            <li>
              <a href="#certificate">Certificate</a>
            </li>
          )}
          {projectView && (
            <li>
              <a href="#projects">Project</a>
            </li>
          )}
          {/* {viewExperience && (
            <li>
              <a href="#experience">Experiences</a>
            </li>
          )} */}
          {/* {viewOpenSource && (
            <li>
              <a href="#opensource">Open Source</a>
            </li>
          )} */}
          {/* {viewAchievement && (
            <li>
              <a href="#achievements">Achievements</a>
            </li>
          )} */}
          {/* {viewBlog && (
            <li>
              <a href="#blogs">Blogs</a>
            </li>
          )} */}
          {/* {viewTalks && (
            <li>
              <a href="#talks">Talks</a>
            </li>
          )} */}

          <li>
            <a href="#contact">Contact Us</a>
          </li>
          <li>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            {/* <a> */}
            <ToggleSwitch />
            {/* </a> */}
          </li>
        </ul>
      </header>
    </Headroom>
  );
}
export default Header;
