/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import "./Footer.scss";
import { Fade } from "react-reveal";
import StyleContext from "../../contexts/StyleContext";

import TelegramIcon from "@mui/icons-material/Telegram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";

export default function Footer() {
  const { isDark } = useContext(StyleContext);
  return (
    <Fade bottom duration={1000} distance="5px">
      <div
        id="works"
        style={{ backgroundColor: isDark ? "black" : "#ffcad4" }}
        className=" mx-auto m-auto h-[300px]  mt-16 sm:h-[250px]"
      >
        <div className="#ffcad4 h-full flex flex-col gap-8 items-center justify-between p-10 sm:p-7">
          <h2
            data-aos="zoom-out"
            style={{ fontWeight: 600, fontSize: "clamp(16px, 5vw, 30px)" }}
          >
            Let's Give me a Voice ❤️
          </h2>
          <div className=" flex items-center justify-center gap-8 sm:gap-5">
            <a
              data-aos="fade-up"
              data-aos-duration="1000"
              href=""
              target="_blank"
              rel="noopener noreferrer"
              className="box font-medium text-white   flex items-center justify-center flex-col"
            >
              <TelegramIcon style={{ color: isDark ? "#fff" : "black" }} />
              <p style={{ fontSize: "clamp(12px, 5vw, 20px)" }}>Telegram</p>
            </a>
            <a
              data-aos="fade-up"
              data-aos-duration="1200"
              href=""
              className="box font-medium text-white  flex items-center justify-center flex-col"
            >
              <WhatsAppIcon style={{ color: isDark ? "#fff" : "black" }} />
              <p style={{ fontSize: "clamp(12px, 5vw, 20px)" }}>WhatsApp</p>
            </a>
            <a
              data-aos="fade-up"
              data-aos-duration="1400"
              href="https://www.instagram.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="box font-medium text-white  flex items-center justify-center flex-col"
            >
              <InstagramIcon style={{ color: isDark ? "#fff" : "black" }} />
              <p style={{ fontSize: "clamp(12px, 5vw, 20px)" }}>Instagram</p>
            </a>
          </div>
          <div style={{ fontSize: "clamp(12px, 3vw, 20px)" }}>
            | Copyright &copy; <span>2023 Quant Tech </span> All rights reserved
            <a href="#"></a> |
          </div>
        </div>
      </div>
    </Fade>
  );
}
