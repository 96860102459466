import React, { useContext } from 'react';
import './workHistory.scss';
import StyleContext from '../../contexts/StyleContext';
import { Fade } from 'react-reveal';

import { workHistoryData } from '../../jsonData/WorkHistoryData';

const WorkHistory = () => {
    const { isDark } = useContext(StyleContext);
    return (
        <div className="container" id='work-history'>
            <div
                style={{
                    marginTop: 16,
                    padding: 4,
                    borderRadius: "24px",
                    // backgroundColor: "#55198b",
                    width: "fit-content",
                }}
            >
                <p
                    style={{
                        padding: 2,
                        fontSize: "clamp(1rem, 5vw, 3rem)",
                        fontWeight: 900,
                        color: "#55198b",
                        paddingInline: "2.5rem"

                    }}
                >
                    <Fade duration={1000} left>
                        Work Experiences
                    </Fade>

                </p>

            </div>
            <div className="section-contain">
                {workHistoryData.map((item, index) => (
                    <Fade duration={1000} left={index % 2 === 0} right={index % 2 !== 0}>
                        <div key={item.id} className="work-exp-card">
                            <div className="image-section-work-exp">
                                <img src={item.imageUrl} alt="work-experience" />
                            </div>
                            <div
                                className="work-exp-title"
                            >
                                <h2 style={{
                                    color: "#55198b"
                                }}>{item.title}</h2>
                                <p style={{
                                    color: isDark ? "#fff" : "black"
                                }}>{item.position}</p>
                                <p style={{
                                    color: isDark ? "#fff" : "black"
                                }}>{item.timePeriod}</p>
                            </div>
                            <div
                                className="work-exp-description"
                                style={{
                                    color: isDark ? "#fff" : "black"
                                }}>
                                <p>{item.description}</p>
                            </div>
                        </div>
                    </Fade>
                ))}
            </div>
        </div>
    );
};

export default WorkHistory;